<template>
<div>
	<div class="cover cover--small" :style="'background-image: url(' + require('@/assets/cover-priorities.jpg') + ')'">
		<h1 class="cover__title">A Voice for You</h1>
	</div>
	<div class="wrapper section">
		<div class="section grid grid--center">
			<div class="grid__item">
				<h1 class="title">A Voice for North Island-Powell River</h1>
				<p class="text">Over the past eight years, the quality of life for most Canadians has stagnated or declined.</p>
				<p class="text">And the constituents of North Island-Powell River are no exception.</p>
				<p class="text">The region has become an unaffordable place to call home. Drug addiction, homelessness and crime are all getting worse.</p>
				<p class="text">And the resource sector has been left hanging by a thread.</p>
				<p class="text">Taxpayers deserve someone who will fight back.</p>
				<p class="text">Below is a preview of some of the policy priorities most important to Aaron - shared in his own words.</p>
				<p class="text">On many of these topics, Aaron has also produced viral, groundbreaking documentaries. The relevant entries are hyperlinked below.</p>
			</div>
			<div class="grid__item">
				<img src="@/assets/priorities-image-1.jpg"/>
			</div>
		</div>
		<div class="section grid grid--center">
			<div class="grid__item order-second-mobile">
				<img src="@/assets/priorities-image-2.jpg"/>
			</div>
			<div class="grid__item">
				<h1 class="title">I ❤️ Canadian Resources</h1>
				<p class="text">Whether it’s forestry or fishing, energy or mining, Canadian resources (and resource workers) are the backbone of our economy.</p>
				<p class="text">It’s time to get pipelines built, dismantle Trudeau’s disastrous “underwater parks” (MPAs) and champion B.C.’s forest industry around the world.</p>
				<a class="watch-video" @click.prevent="toggleYoutubeVideoPopup(getLoggersVideo.id)" href="#"><img class="watch-video__icon" src="@/assets/priorities-tree-icon.svg" /><b>WATCH:</b> Loggers, Protesters and the Fight for Fairy Creek</a>
				<a class="watch-video" @click.prevent="toggleYoutubeVideoPopup(getFishingVideo.id)" href="#"><img class="watch-video__icon" src="@/assets/priorities-fish-icon.svg" /><b>WATCH:</b> No Fishing Allowed: Trudeau’s Plan to Decimate an Entire Industry | Full Movie</a>
				<a class="watch-video" @click.prevent="toggleYoutubeVideoPopup(getOilVideo.id)" href="#"><img class="watch-video__icon" src="@/assets/priorities-pump-icon.svg" /><b>WATCH:</b> How Canadian Energy Can Save the World</a>
			</div>
		</div>
	</div>
	<div class="box">
		<div class="wrapper section section--center">
			<h1 class="title">Axe the carbon tax</h1>
			<p class="text">It shouldn’t cost Canadians an arm and a leg to fill up their tank. </p>
			<p class="text">The carbon tax is a regressive, job-killing tax that drives up the cost of living (especially gas prices) and has no impact on global emissions. It is especially unfair to rural and northern communities and leads to the offshoring of jobs overseas. </p>
			<a class="watch-video" @click.prevent="toggleYoutubeVideoPopup(getGasVideo.id)" href="#"><img class="watch-video__icon" src="@/assets/priorities-fuel-icon.svg" /><b>WATCH:</b> Inflation, Gas Prices and the Trudeau Government's Carbon Tax</a>
			<img src="@/assets/priorities-image-3.jpg"/>
		</div>
	</div>
	<div class="wrapper section">
		<div class="section grid grid--center">
			<div class="grid__item">
				<h1 class="title">Less Government. More Freedom.</h1>
				<p class="text">There is nothing more important than the constitutional rights of Canadians, including freedom of speech, religion, mobility and bodily autonomy.  </p>
				<p class="text">Bill C-11, C-18 and other anti-free speech legislation must be repealed immediately, while new COVID-19 restrictions, including vaccine mandates, must be opposed.  </p>
				<a class="watch-video" @click.prevent="toggleYoutubeVideoPopup(getEndFreeSpeech.id)" href="#"><img class="watch-video__icon" src="@/assets/priorities-megaphone-icon.svg" /><b>WATCH:</b> The End of Free Speech in Canada</a>
			</div>
			<div class="grid__item">
				<img src="@/assets/priorities-image-4.jpg"/>
			</div>
		</div>
		<div class="section grid grid--center">
			<div class="grid__item order-second-mobile">
				<img src="@/assets/priorities-image-5.jpg"/>
			</div>
			<div class="grid__item">
				<h1 class="title">Crackdown on Criminals, Leave law-abiding firearm owners alone. </h1>
				<p class="text">As repeat, violent offenders terrorize our communities, the Trudeau/NDP coalition has weakened bail laws, removed mandatory minimum sentencing, and ignored calls from victims for serious criminal justice reform.</p>
				<p class="text">Unsurprisingly, crime has skyrocketed. It’s time to get tough on repeat, violent offenders and leave law-abiding Canadians alone.  </p>
				<a class="watch-video" @click.prevent="toggleYoutubeVideoPopup(getWarGuns.id)" href="#"><img class="watch-video__icon" src="@/assets/priorities-handcuff-icon.svg" /><b>WATCH:</b> The War on Legal Gun Owners in Canada </a>
			</div>
		</div>
	</div>
	<div class="box">
		<div class="wrapper section section--center">
			<h1 class="title">No to Free Drugs. Yes to Treatment and Recovery. </h1>
			<p class="text">Handing out and decriminalizing hard drugs including fentanyl, heroin and crystal meth, only serves to normalize (and perpetuate) a coercive and destructive addiction. </p>
			<p class="text">Instead, more resources must be invested into treatment, recovery and rehabilitation.  </p>
			<a class="watch-video" @click.prevent="toggleYoutubeVideoPopup(getCanadaDying.id)" href="#"><img class="watch-video__icon" src="@/assets/priorities-maple-down-icon.svg" /><b>WATCH:</b> Canada is Dying</a>
			<img src="@/assets/priorities-image-6.jpg"/>
		</div>
	</div>
	<div class="wrapper section">
		<div class="section grid grid--center">
			<div class="grid__item order-second-mobile">
				<img src="@/assets/priorities-image-7.jpg"/>
			</div>
			<div class="grid__item">
				<h1 class="title">Build more homes. Put taxpayers first. </h1>
				<p class="text">Canada, and especially British Columbia, has become an unaffordable place to live, as money printing, massive deficits and anti-energy policies have turbocharged inflation across the country.  </p>
				<p class="text">It’s time to build more homes, cut taxes, reign in inflation and finally balance the federal budget.   </p>
				<a class="watch-video" @click.prevent="toggleYoutubeVideoPopup(getHousing.id)" href="#"><img class="watch-video__icon" src="@/assets/priorities-house-icon.svg" /><b>WATCH:</b> Why Are Housing Prices in Canada so High?</a>
			</div>
		</div>
		<div class="section grid grid--center">
			<div class="grid__item">
				<h1 class="title">Stand Up for Canada</h1>
				<p class="text">Over the past 8 years, Canada’s history and identity has been dragged through the mud.  </p>
				<p class="text">Instead of tearing down statues, we should be proud of how much we’ve accomplished, and how far we’ve come.   </p>
				<a class="watch-video" @click.prevent="toggleYoutubeVideoPopup(getMacDonald.id)" href="#"><img class="watch-video__icon" src="@/assets/priorities-sculpture-icon.svg" /><b>WATCH:</b> The True Story of Sir John A. Macdonald</a>
			</div>
			<div class="grid__item">
				<img src="@/assets/priorities-image-8.jpg"/>
			</div>
		</div>
	</div>

   <div class="jumbotron jumbotron--large" :style="'background-image: url(' + require('@/assets/jumbotron.jpg') + ')'"></div>

   <div class="section section--overlay section--center" :style="'background-image: url(' + require('@/assets/cover-home.jpg') + ');'">
      <div class="wrapper">
         <h1 class="title title--white title--spaced">Join the Movement to Bring Common Sense Back to Canada</h1>
         <app-form source="post_cut_off" submit-text="Register to Vote" :quick-form="true" destination="/vote?stepTwo"></app-form>
      </div>
   </div>
</div>
</template>

<script>
import AppForm from '@/components/utils/AppForm.vue'
import videosMixin from '@/videos-mixin.js'
export default {
	mixins: [videosMixin],
   components: {
      AppForm
   },
	computed: {
		getLoggersVideo () {
			return this.getVideo("nFlWy9LwDpc");
		},
		getFishingVideo () {
			return this.getVideo("IPHDyscI7wo");
		},
		getOilVideo () {
			return this.getVideo("AqF7_3sSpzo");
		},
		getGasVideo () {
			return this.getVideo("Vi81g37SIZU");
		},
		getEndFreeSpeech () {
			return this.getVideo("k4ZbtOjvg1E");
		},
		getCanadaDying () {
			return this.getVideo("_RaWzJUeT0o");
		},
		getWarGuns() {
			return this.getVideo("n7Xpk9VSvI8");
		},
		getHousing() {
			return this.getVideo("2bz3OuarClY");
		},
		getMacDonald () {
			return this.getVideo("Yo-vIsr2MYg");
		},
	}
}
</script>

<style scoped>
.watch-video { display: block; color: #062165; padding: 8px 0; }
.watch-video:last-of-type { margin-bottom: 30px; }
.watch-video__icon { width: 20px; margin-right: 10px; }

@media (max-width: 768px) {
   .order-second-mobile { order: 2; }
}
</style>
